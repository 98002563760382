import React from 'react';
import Machine from '../../images/machine-learning.svg';
import Deep from '../../images/deep.svg';
import PredictiveAnalytics from '../../images/predictive-analytics.svg';
import Imagge from '../../images/image.svg';
import Video from '../../images/video.svg';
import Cog from '../../images/cog.svg';
function Services({ data }) {

  return (
          <div id="services" className="about partnerHotspot">
            <div className="containerwrap plain-bg">
                <div className="aboutTitle">{data.title}</div>
                <h2 className="aboutHeading">
                    {data.heading}
                </h2>
                <div className="aboutOptions">
                    {
                      data.pills.map((pill, index) => (
                        <div className="aboutOptionsColumn" key={index}>
                            <span className="aboutOptionsData">{pill}</span>
                        </div>
                      ))
                    }
                </div>
            </div>
          </div>
  )
}

export default Services
