import React from "react";
import consultPartner from "../../images/aws-partner-tier-badge.svg";

function Overview({ aws }) {
  return (
    <>
      <div className="partnerHotspot" id="overview">
        <div className="partner">
          <div className="partner_container">
            <div className="row">
              <div className="partnerLeftSection">
                <div className="partnerTitle">{aws?.title}</div>
                <div className="partnerHeading">{aws?.heading}</div>
                <div className="partnerDescription">{aws?.description}</div>
              </div>
              <div className="partnerRightSection">
                <div className="aws_par_wrap">
                  <div className="aws_inside">
                    <img src={consultPartner} alt="consultPartner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
