import React from 'react'


function WorkingWithUs({data}) {
    return (
        <div className="workWithUs" style={{backgroundImage: `url(${require("../../images/plateform-bg.svg").default})`}}>
            <div className="partner_container">

                <div className="workWithUsTitle">{data.WorkingSection.title} </div>
                <div className="workWithUsHeading">{data.WorkingSection.heading1} <span>{data.WorkingSection.heading2}</span></div>
            </div>

        </div>
    )
}

export default WorkingWithUs
