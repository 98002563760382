import { Link } from 'gatsby';
import React from 'react';
function OpsioAWS({ data, locale }) {

  return (
    <div className="partnerHotspot" id="opsio-aws">
      <div className="aboutMission opsioAws"> 
          <div className="aboutUsLeftSection containerwrap" >
              <div className="aboutMissionTitle">{data.title}</div>
              <div className="aboutUsHeading" style={{marginTop:0, height:"48px", color:"white"}}>{data.heading}</div>
              <div className="aboutUsDescription" style={{marginTop: "36px", color:"white"}}>{data.des1}</div>
              <div className="aboutUsDescription"  style={{marginTop: "36px", color:"white"}}>{data.des2}</div>
              <div className="opsBtnwrap pt-5">
                <Link to={`/successstories/Migration-Of-Et-Network-To-AWS/`} className="opsBtnlink"><p>{data.btn}</p></Link>
              </div>
          </div>
      </div>
    </div>
  )
}

export default OpsioAWS;
