import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { Link } from "gatsby";

function HeroSection({ data }) {
  const [slug, setSlug] = useState("");
  const handleClick = (val) => {
    setSlug(val);
    if (val === "getting-started") {
      window.scrollTo({ top: document.body.scrollHeight });
    } else {
      navigate(`/aws-public-support-statement/#${val}`, { replace: true });
    }
  };

  useEffect(() => {
    let selectedSlug = window?.location.href.split("#")[1];
    if (selectedSlug) {
      setSlug(selectedSlug);
    }
  });

  return (
    <div className="platform_upper_class">
      <div className="platformHeroSection">
        <div className="containerwrap">
          <div className="platformHeroSectionTitle">
            <h1>{data && data.HeroSection.title}</h1>
          </div>
          <div className="platformHeroSectionHeading">
            {data.HeroSection.heading1} <span>{data.HeroSection.heading2}</span>
          </div>
          <div className="platformHeroSectionDescription">
            {data.HeroSection.des}
          </div>
          <div className="opsBtnwrap middelBtn">
            <Link className="opsBtnlink" to={`/contact-us/`}>
              <p>{data.HeroSection.btn}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="pageNav pltfrmPage awsPage">
        <ul className="pageNav-ul">
          {data.navbar.map((val, i) => (
            <li
              key={i}
              onClick={(e) => handleClick(val.path)}
              className={slug === val.path ? "active" : ""}
            >
              {val.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HeroSection;
