import React from "react";
import WorkingWithUs from "../platform/workingWithUs";
import { Link } from "gatsby";

const WhyAws = ({ data, locale }) => {
  return (
    <div className="solSection partnerHotspot" id="why-aws">
      <div className="solWrap">
        <div className="solsecHead">
          <h4 className="smallHeading">{data.title}</h4>
        </div>
        <div className="solsecBody">
          <div className="solbodyWrap">
            <div className="partnercol">
              {data.bulletpoints.map((val, index) => {
                return (
                  <div className="solcolWrap" key={index}>
                    <div className="inside_social_wrap">
                      <div className="outside_wrap">
                        <div className="partnerListTitle">{val.name}</div>
                      </div>
                      <div className="outside_wrap">
                        <div className="solName">{val.des}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <WorkingWithUs data={data} />

      <div className="tellUsAbout awsWorking">
        <div className="partner_container w-100">
          <div className="tellUsAboutHeading">
            {data.WorkingSection.footer.heading}
          </div>
          <div className="tellUsAboutEnquireNow">
            <div className="tellUsAboutDescription">
              {data.WorkingSection.footer.text}
            </div>
            <div className="d-flex startingCloud p-0">
              <input
                type="text"
                name="reply_to"
                className="startingCloudInput"
                placeholder={data.WorkingSection.footer.email}
              />
              <div className="opsBtnwrap mx-2">
                <Link className="opsBtnlink" to={`/contact-us/`}>
                  <p>{data.WorkingSection.footer.btn}</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer-icon relative-icon"
        style={{
          backgroundImage: `url(${
            require("../../images/footer-icon.svg").default
          })`,
        }}
      ></div>
    </div>
  );
};

export default WhyAws;
