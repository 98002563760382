import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo";
import HeroSection from "../components/aws/Hero";
import Overview from "../components/aws/overview";
import OpsioAWS from "../components/aws/opsioAws";
import WhyAws from "../components/aws/whyAws";
import Services from "../components/aws/services";
import SuccessStories from "../components/SuccessStories";

const AwsPage = ({ data }) => {

    const awsData = data.allStrapiAwsPage.edges[0].node;

    return (
        <Layout slug={awsData.slug} locale={awsData.locale}>
            <SEO title={awsData.MetaTitle} description={awsData.MetaDes} slug={awsData.slug} locale={awsData.locale} />
            <HeroSection data={awsData} locale={awsData.locale} />
            
            <VisibilityWrap sectionId="overview">
                <Overview aws={{ ...awsData.overview, logo: awsData.logo[0] }} locale={awsData.locale} />
            </VisibilityWrap>
            
            <VisibilityWrap sectionId="opsio-aws">
                <OpsioAWS data={awsData.opsioAws} locale={awsData.locale} />
            </VisibilityWrap>

            <VisibilityWrap sectionId="why-aws">
                <WhyAws data={{ ...awsData.whyAws, WorkingSection: awsData.WorkingSection }} locale={awsData.locale} />
            </VisibilityWrap>

            <VisibilityWrap sectionId="services">
                <Services data={awsData.services} locale={awsData.locale} />
            </VisibilityWrap>

            <VisibilityWrap sectionId="successstories">
                <SuccessStories partner={true} locale={awsData.locale} />
            </VisibilityWrap>

            <VisibilityWrap sectionId="getting-started">
                <div className="partnerHotspot" style={{ height: "1px" }} id="getting-started" />
            </VisibilityWrap>
        </Layout>
    );
}
 
export default AwsPage;

export const query = (graphql`
    query AwsPage($locale:String!) {
        allStrapiAwsPage(filter: {locale: {eq: $locale}}) {
            edges {
                node {
                    locale
                    slug
                    MetaTitle
                    MetaDes
                    overview {
                        title
                        heading
                        description
                    }
                    logo {
                        url
                    }
                    HeroSection {
                        title
                        heading1
                        heading2
                        btn
                    }
                    navbar {
                        label
                        path
                    }
                    opsioAws {
                        title
                        heading
                        des1
                        des2
                        btn
                    }
                    whyAws {
                        title
                        bulletpoints {
                            name
                            des
                        }
                    }
                    services {
                        title
                        heading
                        pills
                    }
                    WorkingSection {
                        title
                        heading1
                        heading2
                        footer {
                            heading
                            text
                            btn
                            email
                        }
                    }
                }
            }
        }
    }
`);


const VisibilityWrap = ({ sectionId, children }) => {

  
    const [active, setActive] = useState(false);
    const [screenHeight, setScreenHeight] = useState(0);
  
    setTimeout(() => {
        setActive(true);
    }, 1200);
  
    useEffect(() => {
      const handler = () => {
        setActive(false);
        setTimeout(() => {
            setActive(true);
        }, 1000);
      };
  
      setScreenHeight(window.innerHeight);
      
      const upperClass = document.getElementsByClassName('platform_upper_class')[0];
      
      upperClass.addEventListener("click", handler);
      
      return () => {
        upperClass.removeEventListener("click", handler);
      }
    });
  
    return (
      <VisibilitySensor
        active={active}
        partialVisibility={sectionId !== "getting-started"}
        delayedCall={true}
        offset={sectionId !== "getting-started" ? {
          top: screenHeight > 0 ? screenHeight / 2 : 300,
          bottom: screenHeight > 0 ? screenHeight / 2 : 300
        } : null}
        onChange={(isVisible) => {
            if(isVisible) {
                navigate(`/aws-public-support-statement/#${sectionId}`);
            }
        }}
      >
        {children}
      </VisibilitySensor>
    )
  }